<template>
  <div class="know-your-customer">
    <NavBack text="Back" :action="goBack"></NavBack>
    <MenuAndLogo v-if="$isMobile" referralIconBackgroundColor="#fff"></MenuAndLogo>
    <div class="wrapper">
      <h2 class="page-title has-text-weight-semibold has-text-centered my-5">Identity Verification</h2>
      <div id='veriff-root'></div>
    </div>
    <div
      v-if="governmentIdStatus === 'failed'"
      class="reject-reasons">
      There was an issue with the previous government ID that you uploaded 😥 Please see the following:
      <ul>
        <li v-for="(reason, index) in governmentIdRejectionReasons" :key="index">{{reason}}</li>
      </ul>
    </div>
  </div>
</template>
<script>
import { Veriff } from '@veriff/js-sdk'
import { createVeriffFrame, MESSAGES } from '@veriff/incontext-sdk'
import NavBack from '@components/desktop/header-nav-back.vue'
import MenuAndLogo from '@views/index/MenuAndLogo.vue'
import { getProfileDetails } from '@api/profile'

export default {
  data() {
    return {
      governmentIdStatus: '',
      governmentIdRejectionReasons: [],
    }
  },
  created() {
    this.getVerificationData()
  },
  mounted() {
    this.initVeriff()
  },
  components: {
    NavBack,
    MenuAndLogo,
  },
  computed: {
    hashid() {
      const investor = JSON.parse(localStorage.getItem('investor'))
      return investor.hashid
    },
  },
  methods: {
    initVeriff() {
      const veriff = Veriff({
        apiKey: process.env.VUE_APP_VERIFF_API_KEY,
        parentId: 'veriff-root',
        onSession: (err, response) => {
          if (err || !response.verification.url) return

          createVeriffFrame({
            url: response.verification.url,
            onEvent: (msg) => {
              switch (msg) {
              // case MESSAGES.CANCELED:
              case MESSAGES.FINISHED:
                this.handleNext()
                break
              }
            }
          })
        }
      })
      veriff.setParams({
        vendorData: this.hashid,
      })
      veriff.mount()
    },
    handleNext() {
      const returnUrl = this.$route.query.returnUrl
      const route = returnUrl ? decodeURIComponent(returnUrl) : '/investorProfile'
      this.$router.push(route)
    },
    getVerificationData() {
      getProfileDetails().then((data) => {
        if (!data.success) return
        const { governmentIdStatus, governmentIdRejectionReasons } = data.data
        this.governmentIdStatus = governmentIdStatus
        this.governmentIdRejectionReasons = governmentIdRejectionReasons
      })
    },
    goBack() {
      this.$router.go(-1)
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@assets/style/desktop-constants.scss';
@import '@assets/style/addy-variables.scss';

.know-your-customer {
  .wrapper {
    padding: 0 25px;
    .page-title {
      font-size: 24px;
    }
    #veriff-root {
      max-width: unset;
      ::v-deep .veriff-container {
        > input {
          height: 50px;
          border-radius: 12px;
          &[type=text] {
            border: 1px solid #bdc0cf;
            box-shadow: none;
          }
        }
        .veriff-submit {
          background-color: $addyBlue;
          font-family: 'Proxima Nova', sans-serif;
          font-size: 18px;
          font-weight: 600;
          color: #fff;
        }
      }
    }
  }
  .reject-reasons {
    padding: 25px;
    margin-top: 22px;
    font-size: 12px;
    line-height: 14px;
    color: #C91717;
    ul {
      padding-left: 8px;
      li {
        font-size: 12px;
        line-height: 14px;
        color: #C91717;
        list-style: inside;
      }
    }
  }
}

@media only screen and (min-width: $min-viewport-width) {
  .know-your-customer {
    min-height: calc(#{$min-height-mobile-container} - 23px);
    max-width: $mobile-wrapper-width;
  }
}
</style>
